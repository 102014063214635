// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Qa from 'components/Qa';
import Accordion from 'components/Accordion';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    padding-bottom: ${props => props.paddingBottom && '0px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '30px'};
  }

  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'ckuipiaig5msg0b277ghnlq4a', true)}
          breadCrumb={getMarkdown(page, 'ckuip8bps5dmf0c73lsao8vad', true)}
          asset={getMarkdown(page, 'ckuipiaig5msg0b277ghnlq4a', false, true)}
          isOncologia
          titleWidthLap="30rem"
          boxInfoTextWidthDesk="28.125rem"
          boxContentPaddingBottomDesk="0"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginTopTitle="3.125rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          boxImagesImgOuterWrapperWidth="unset"
          marginTopBoxInfoMob="-7rem"
          marginTopBoxInfoTab="-9rem"
          positionLeftImageMarketingMobile320="1.725rem"
          positionLeftImageMarketingMobile="0.7rem"
          overflowContainerMobile="hidden"
          positionTopImageMarketingMobile="0.rem"
          widthImgMarketingMobile="10rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingLap="25rem"
          marginBottomTitleDesk="2.5rem"
          boxContentFlexDirectionTab="column-reverse"
          boxContentFlexDirectionLap="unset"
          titleWidthMob="10rem"
          titleWidthMob320="50%"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxInfoHeightLap="9rem"
          titleWidthDesk="32.5rem"
          widthImgMarketingDesk="35.56rem"
          heightImgMarketingDesk="25.68rem"
          ImgAdjustWidth="35.56rem !important"
          ImgAdjustHeight="25.68rem !important"
          marginBreadCrumb="0 0 0"
          justifyContentFeaturedMarketing="end"
          boxInfoHeightMobile="8rem"
        />
      </Part>

      <Part gridColumn={'2 / -2'} largeTitle notLeft paddingBottom>
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckuzt5umo36qs0b77dl22dn9y', true)}
          fragments={getMarkdown(page, 'ckuzt5umo36qs0b77dl22dn9y')}
          isShowing
          withoutBorderTop
          containerWidth="50%"
          containerPaddingDesk="4.375rem 0 1.5rem 0"
          containerPaddingLaptop="2.375em 0 0.4rem 0"
          containerWidthLap="100%"
          notTitleBold
          isBottom
          prontoAtendimento
          isFacilities
          containerPaddingLap="2.375rem 0"
        />
      </Part>

      <Part
        gridColumn={'2 / -2'}
        paddingTopMob="70px"
        paddingBottomMob="0"
        largeTitle
        notLeft
        borderTop
        paddingTop
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cky8xjvzk89nj0c76i3goj8yu', true)}
          fragments={getMarkdown(page, 'cky8xjvzk89nj0c76i3goj8yu')}
          isShowing
          withoutBorderTop
          containerWidth="50%"
          containerPaddingDesk="0 0 1.5rem 0"
          containerPaddingLaptop="0.125em 0 0.4rem 0"
          containerWidthLap="100%"
          notTitleBold
          isBottom
          prontoAtendimento
          isFacilities
          boxContentPaddingBottomLaptop="0rem"
          containerPaddingLap="2.375rem 0"
          hasIframe
          notDuplicateVideo
        />
      </Part>

      <Part gridColumn={'2 / -2'} notLeft paddingTop>
        <Accordion
          spanDisplay="inline"
          colorText="#000"
          markdownContent={getMarkdown(page, 'cl0r6btgr9tog0dk5v41xye31', true)}
          contentTextMarginBottom="0rem"
          marginTopUl="0.625rem"
        />
        <Accordion
          spanDisplay="inline"
          colorText="#000"
          markdownContent={getMarkdown(page, 'cl0r6kq4ma0mw0cmw34cmbk9u', true)}
          contentTextMarginBottom="0rem"
        />
        <Accordion
          spanDisplay="inline"
          colorText="#000"
          markdownContent={getMarkdown(page, 'cl0r6rpbwa5dq0blvavt6b5n4', true)}
          contentTextMarginBottom="0rem"
        />
        <Accordion
          spanDisplay="inline"
          colorText="#000"
          markdownContent={getMarkdown(page, 'cl0r6socea5p50cmtwchqiq8x', true)}
          contentTextMarginBottom="0rem"
          noBorderBottomLastElement
        />
      </Part>
    </GridContainer>
  );
};
const SocialAppointment = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckuipiaig5msg0b277ghnlq4a', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckuznoljs1yb40d21a55vfzv0" }) {
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <SocialAppointment page={response.gcms.site.pages} />;
      }}
    />
  );
};
